import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Sidebar } from '../Sidebar/Sidebar'
import { TitleRefinementBar } from '../TitleRefinementBar/TitleRefinementBar'
import { SearchFilterBar } from '../SearchFilterBar/SearchFilterBar'
import { Notification } from '../../Notification/Notification'
import { ComparisonBar } from '../ComparisonBar/ComparisonBar'
import { Banner } from '../Banner/Banner'
import { ProductUpsellSlot } from '../ProductUpsellSlot/ProductUpsellSlot'
import { FooterInfo } from '../FooterInfo/FooterInfo'
import { LoadAnimationWithOverlay } from '../../LoadAnimationWithOverlay/LoadAnimationWithOverlay'
import { ProductWithComparison } from '../ProductWithComparison/ProductWithComparison'
import { APP_SET_DATA_COMPLETE } from '../../../constants/appConstants'
import { NoResultsApp } from '../NoResultsApp/NoResultsApp'
import { CategoryListingSection } from '../CategoryListingSection/CategoryListingSection'
import { ProductContainer } from '../../_styled/ProductContainer'
import useAmplitude from '../../../hooks/useAmplitude'

const ConnectedComponent = (props) => {
  const dispatch = useDispatch()
  const [isSearchResult, setIsSearchResult] = useState(false)
  const [isSearchResultAutocomplete, setIsSearchResultAutocomplete] = useState(false)
  const { trackEvent: trackAmplitudeEvent, mapAmplitudeProduct } = useAmplitude()

  let previousPagePath = ''

  // Had unknown issue with document.referrer before in Amplitude init
  // Added try catch block to avoid crashes
  try {
    if (typeof window.location !== 'undefined' && document.referrer) {
      previousPagePath = (new URL(document.referrer)).pathname
    }
  } catch (error) {
    console.error('Error getting previous page path: ', error)
  }

  useEffect(() => {
    dispatch({ type: APP_SET_DATA_COMPLETE })

    const currentUrl = new URL(window.location)
    const searchParams = currentUrl.searchParams
    const searchCompleted = searchParams.get('search-completed')

    if (searchCompleted && props?.keyword !== null && props?.keyword !== '') {
      searchParams.delete('search-completed')
      currentUrl.search = searchParams.toString()
      window.history.replaceState(null, '', currentUrl.toString())

      if (searchCompleted === 'regular') {
        trackAmplitudeEvent('search completed', {
          SearchResultCount: props?.totalResults,
          Keywords: props?.keyword,
          SearchType: 'Regular',
          PreviousPagePath: previousPagePath,
        })
      }

      if (searchCompleted === 'autocomplete') {
        setIsSearchResultAutocomplete(true)
      }

      setIsSearchResult(true)
    }
  }, [])

  const handleSearchResultsClicked = (index, product) => {
    if (isSearchResult) {
      trackAmplitudeEvent('search results clicked', {
        Rank: index,
        SearchResultLocation: 'ResultsPLP',
        Product_Engagement: [mapAmplitudeProduct(product)],
        PreviousPagePath: previousPagePath,
        SearchType: isSearchResultAutocomplete ? 'Autocomplete' : 'Regular',
      })
    }
  }

  const buildProductGrid = (data) => {
    const cardOrderIndex = props.cardOrderNumber ? (parseInt(props.cardOrderNumber) - 1) : -1
    let productGrid = []
    let populated = false
    for (let index = 0; index < props.productDataP.length; index) {
      if (index === cardOrderIndex && !populated) {
        productGrid.push(<ProductUpsellSlot upSellHtml={props.productCardZone} />)
        populated = true
      } else {
        productGrid.push(
          <ProductContainer>
            <ProductWithComparison
              onClick={() => handleSearchResultsClicked(index, data[index])}
              key={`${data[index].productPartNumber}${index}`}
              product={data[index]}
              promotions={props.promotionData[data[index].productPartNumber] ? props.promotionData[data[index].productPartNumber] : null}
            />
          </ProductContainer>
        )
        index++
      }
    }
    return productGrid
  }

  if (props.productDataP.length === 0) {
    return (
      <NoResultsApp />
    )
  }

  const constructorioProps = {
    'data-site-area': 'body',
    'data-cnstrc-search': props?.keyword ? 'true' : 'false',
    'data-cnstrc-browse': props?.keyword ? 'false' : 'true',
    'data-cnstrc-num-results': props.totalResults,
    ...(typeof props?.filterData !== 'undefined'
    && props?.filterData !== null
    && !props?.keyword
    && window?.location?.pathname !== "/q"
    && Object.hasOwn(props.filterData, "key")
    && Object.hasOwn(props.filterData, "value") ? {
      'data-cnstrc-filter-name': props.filterData.key,
      'data-cnstrc-filter-value': props.filterData.value
      } : {})
  }

  const isMobile = typeof window.location !== 'undefined' ? window.screen.width < 769 : window.isMobile
    return (
    <AppMain
      id='mainContent'
      data-site-area="body"
      {...constructorioProps}
    >
        <Banner html={isMobile ? props.topMobileBanner : props.topBanner} />
          <Sidebar visible={props.mobileRefinement} />
          <AppSection>
            <AppTopNav>
              <TitleRefinementBar title={props.title} />
              <SearchFilterBar />
            </AppTopNav>
            {props.recommendationData.length > 0 && props.recommendationData[0].result.length > 0  &&
            <CategoryListingSection title={props.title} products={props.recommendationData} />
            }
            <TitleRefinementH1>{props.h1 !== "" ? props.h1 : props.title}</TitleRefinementH1>
            {props.productLoadP ? <LoadAnimationWithOverlay /> : ''}
            {buildProductGrid(props.productDataP)}
            <SearchFilterBar location='bottom' />
          </AppSection>
          {props.footerInfo &&
            <FooterInfo data={props.footerInfo} />
          }

          <ComparisonBar show={props.showCompareBar} />
          <Notification
            notificationText={props.notification.notificationText}
            buttonText={props.notification.buttonText}
            buttonURL={props.notification.buttonURL}
            background={props.notification.background}
            show={props.notification.active}
          />
        <Banner html={isMobile ? props.bottomMobileBanner : props.bottomBanner} />
        </AppMain>
  )
}

const AppMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
`

const AppSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 100vh;
  width: 100%;
  padding-top: 1rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - 28rem);
  }
`
const AppTopNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;
`
const TitleRefinementH1 = styled.h1`
  font-size: 1.5rem;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: .1rem;
  padding: 0.625rem;
  @media (max-width: 767px){
    width: 60%;
    font-size: 1.2rem;
    left: 0;
    padding-left: 2rem; 
  }
`
const mapStateToProps = function (state) {
  return {
    productDataP: state.productData.productData,
    promotionData: state.productData.promotionData,
    recommendationData: state.productData.recommendationData,
    productLoadP: state.app.loading,
    mobileRefinement: state.app.filterMenuOpen,
    title: state.app.title,
    showCompareBar: state.compare.barVisible,
    notification: state.notification,
    topBanner: state.app.topBanner,
    bottomBanner: state.app.bottomBanner,
    topMobileBanner: state.app.topMobileBanner,
    productCard: state.app.productCard,
    bottomMobileBanner: state.app.bottomMobileBanner,
    footerInfo: state.app.footerInfo,
    productCardZone: state.app.productCardZone,
    cardOrderNumber: state.app.cardOrderNumber,
    keyword: state.app.keyword,
    groupId: state.app.groupId,
    collectionId: state.app.collectionId,
    brandPage: state.app.brandPage,
    totalResults: state.app.totalPageResults,
    h1: state.app.h1,
    filterData: state.app.filterData,
    index: state.app.index
  }
}

export const SearchApp = connect(mapStateToProps)(ConnectedComponent)
